<template>
  <v-app id="inspire">
    <v-main>
      <v-container fill-height fluid>
        <v-layout align-center justify-center fill-height>
          <v-flex text-center>
            <h1 class="text-h3 primary--text">Whoops, 404</h1>
            <p>The page you were looking for does not exist</p>
            <v-btn :to="prev.fullPath" outlined color="primary">Go back</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'FourOhFour',
  data: () => ({
    prev: {}
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prev = from
    })
  },
  created () {
    this.$ssrContext && this.$ssrContext.res.status(404)
  }
}
</script>
